/* 字体和颜色 */
:root {
  --font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  --color-background: #f8f8f7;
  --color-text: #1d1d1f;
  --color-accent: #0071e3;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  background-color: var(--color-background);
  color: var(--color-text);
}

/* 基础布局 */
.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
}

.App-header {
  background-color: var(--color-background);
  color: var(--color-text);
  padding: 50px 0;
  text-align: center;
}

/* 表单样式 */
form {
  background-color: var(--color-background);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

input[type="text"], select {
  width: calc(100% - 22px);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

button {
  background-color: var(--color-accent);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: darken(var(--color-accent), 10%);
}

/* 故事和图像展示 */
h3 {
  font-weight: normal;
  font-size: 24px;
  margin-top: 40px;
}

p {
  font-size: 18px;
  line-height: 1.6;
}

img {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 10px;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .App-header {
    padding: 30px 0;
  }

  form {
    padding: 15px;
  }

  input[type="text"], select, button {
    font-size: 14px;
  }

  h3 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }
}
